import React from "react"
import Layout from "../components/Layout"
import { graphql, useStaticQuery } from "gatsby"
import GallerySlide from "../components/GallerySlide/GallerySlide"
import SectionHeadingH2 from "../components/SectionHeadingH2/SectionHeadingH2"
import photoPlano from "../images/promociones/M6 Pau II/Planos/planos.png"
import photoSituacion from "../images/promociones/M6 Pau II/Situacion/situacion.png"

const PauIIPage = () => {
  const images = useStaticQuery(graphql`
    query {
      photosObraPauII: allFile(
        filter: { relativeDirectory: { eq: "promociones/M6 Pau II/Obra" } }
      ) {
        nodes {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
            id
          }
        }
      }
    }
  `)

  const photosObra = images.photosObraPauII.nodes.map(node => {
    return {
      image: node.childImageSharp.fluid,
      imageThumbnail: `${node.childImageSharp.fluid.src}`,
      imageModal: `${node.childImageSharp.fluid.src}`,
      id: node.childImageSharp.id,
    }
  })

  return (
    <Layout>
      <div className="container">
        <SectionHeadingH2 sectionTitle="M6 Pau II" />
        <div className="text-component max-width-sm margin-x-auto">
          <p>
            14 exclusivas viviendas de tipo unifamiliar, en un entorno
            inigualable de Puertollano. Para los que piensan que el confort
            merece ser elevado a la categoría de bien indispensable. 
          </p>

          <p>
            Dotadas de una parcela de amplias dimensiones, la superficie útil se
            distribuye entre una generosa terraza-jardín, sótano, planta baja,
            planta primera y buhardilla bajo cubierta con terraza solarium
            integrada.
          </p>
        </div>
        <div className="grid gap-xl margin-y-xl">
          <div className="col-12 col-6@sm">
            <p className="text-md font-primary-bold margin-bottom-xs">Obra</p>
            <GallerySlide
              gallerySlideListItems={photosObra}
              idLightbox="photosObra"
            />
          </div>
          <div className="col-12 col-6@sm">
            <p className="text-md font-primary-bold margin-bottom-xs">Plano</p>
            <img src={photoPlano} alt="" />
          </div>
          <div className="col-12 col-6@sm">
            <p className="text-md font-primary-bold margin-bottom-xs">
              Ubicación
            </p>
            <img src={photoSituacion} alt="" />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default PauIIPage
